import React, {useEffect} from "react"

import { Container } from "react-bootstrap"

import styles from "./TabbedNav.module.scss"

const TabbedNav = ({ hash, tabs, className = "" }) => {
  const scrollToHash = (id) => {
    const el = document.querySelector(id)
    if (el) {
      let paddingTop = window.getComputedStyle(el).getPropertyValue("padding-top")
          paddingTop = paddingTop.substring(0, paddingTop.length - 2)
  
      let elementPosition = el.getBoundingClientRect().top
      let headerOffset = document.getElementById("header").offsetHeight
      let offsetPosition = elementPosition - headerOffset + parseInt(paddingTop)

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      })
    }
  }

  useEffect(() => {
    let initScrollTimeout

    if (hash) {
      initScrollTimeout = setTimeout(() => scrollToHash(hash), 1000)
    }

    return () => {
      clearTimeout(initScrollTimeout)
    }
  }, [hash])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Container
      as={"section"}
      className={`${styles.tabbedNavContainer} ${className}`}
    >
      <nav className={styles.tabbedNav}>
        {tabs.map(({ label, href }) => (
          <a
            key={label}
            tabIndex={0}
            href={`${href}`}
            onClick={(e) => {
              window.history.pushState(null, null, href)
              scrollToHash(href)
              e.preventDefault()
            }}
            className={styles.link}
          >
            {label}
          </a>
        ))}
      </nav>
    </Container>
  )
}

export default TabbedNav
