import React from "react"
import ExternalLink from '../../../components/Modal/ExternalLink';
import VideoLink from '../../../components/Modal/Video';

export const useGetStartedData = () => {
  const HEADER = {
    title: "Implementation: Get Started in 5 Steps",
  }

  const TABS = [
    {
      label: "Prepare your practice",
      href: "#prepare",
    },
    {
      label: "Certify in Palforzia REMS",
      href: "#certify",
    },
    {
      label: "Order Palforzia for Your Practice",
      href: "#order",
    },
    {
      label: "Identify Patients",
      href: "#identify",
    },
    {
      label: "Prescribe Palforzia",
      href: "#prescribe",
    },
  ]

  const REFERENCES = []

  const REFERENCES_HEADER = "References"

  const CARDS = [
    {
      id: "prepare",
      header: {
        title: "Prepare your practice for PALFORZIA",
      },
      image: "/get-started-1.png",
      alt: "Two children playing together",
      cta: {
        showFileIcon: false,
        label: "Visit palforziarems.com",
        target: "_blank",
        url: "https://palforziarems.com/",
      },
      richTopics: [
        "The following videos provide considerations for incorporating PALFORZIA into your practice with the help of the 5 S’s: Staffing, Space, Selection and Support, and Scheduling.*",
      ],
      disclaimer: "*The videos are the opinions of the clinicians based on their experiences and not the recommendation of Aimmune.",
      videos: [
        {description: "Find out about staffing considerations for your practice.", 
        alt:"Staffing considerations video thumbnail",
        thumbnail: "/Staffing.png",
        id:"935958877",
        video: `<iframe src="https://player.vimeo.com/video/935958877" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`},
        {description: "Review office space considerations for the three phases of treatment.", 
        alt:"Office space considerations video thumbnail",
        thumbnail: "/Space.png",
        id:"936262247",
        video: `<iframe src="https://player.vimeo.com/video/936262247" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`},
        {description: "Learn more about appropriate patients for PALFORZIA and available financial assistance programs.", 
        alt:"Patient selection and support video thumbnail",
        thumbnail: "/Patient_Selection.png",
        id:"935958786",
        video: `<iframe src="https://player.vimeo.com/video/935958786" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`},
        {description: "Consider these points when scheduling patient visits throughout the treatment process.", 
        alt:"Scheduling considerations video thumbnail",
        thumbnail: "/Scheduling.png",
        id:"935958939",
        video: `<iframe src="https://player.vimeo.com/video/935958939" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`},
      ],
    },
    {
      id: "certify",
      header: {
        title: "Certify in the PALFORZIA REMS Program",
      },
      image: "/get-started-1.png",
      alt: "Smiling girl",
      cta: {
        showFileIcon: false,
        label: "Visit palforziarems.com",
        target: "_blank",
        url: "https://palforziarems.com/",
      },
      richTopics: [
        "PALFORZIA is only available through the PALFORZIA REMS Program. A REMS, or Risk Evaluation and Mitigation Strategy, is a drug safety program that the FDA requires for certain medications with serious safety concerns to help ensure that the potential benefits of the medication outweigh its risks.",
        "The goal of PALFORZIA REMS is to mitigate the risk of anaphylaxis associated with PALFORZIA. The healthcare provider who prescribes PALFORZIA (prescriber) and the practice that administers PALFORZIA (healthcare setting) must be certified in PALFORZIA REMS. Prescribers must also enroll their patients in PALFORZIA REMS before treatment can begin."
      ],
      REMS: {
        header: "Who needs to get REMS certified, and how?",
        copy: [
          ["Healthcare Prescriber and affiliated Healthcare Setting must ", <ExternalLink url="https://palforziarems.com/#Main">enroll in the REMS Program</ExternalLink>," to administer PALFORZIA."],
          [<strong>Prescribers: </strong>, "Healthcare Practitioners must certify in the REMS Program prior to prescription of PALFORZIA."],
          [<strong>Prescriber NPI: </strong>, "In order for your REMS certification to be accurate, make sure to ", <ExternalLink url="https://npiregistry.cms.hhs.gov/registry/">double check that the address associated with NPI is correct</ExternalLink>, ", including suite/floor, at the NPI Registry."],
          [<strong>Healthcare Setting: </strong>, "REMS certification is required to administer PALFORZIA to patients at your practice."],
          [<strong>Patients: </strong>, "Before patients can be treated with PALFORZIA, the provider needs to enroll them in REMS."],
          ["Take the first step toward implementation by certifying in the PALFORZIA REMS Program. To begin enrollment, first ensure each healthcare setting has a unique National Provider Identifier (NPI). ", <ExternalLink url='https://nppes.cms.hhs.gov/#/'>Obtain an NPI at the National Plan and Provider Enumeration System website.</ExternalLink>],
        ]
      },
    },
    {
      id: "order",
      header: {
        title: "Order PALFORZIA For Your Practice",
      },
      image: "/get-started-2.png",
      alt: "PALFORZIA office dose kit",
      cta: {
        showFileIcon: false,
        label: "Order Now",
        target: "_blank",
        url: "https://PALFORZIAOfficeDoseKit.com",
      },
      richTopics: [
        "Once PALFORZIA REMS requirements are completed, prepare for in-office administration by ordering your PALFORZIA Office Dose Kit (ODK) through the PALFORZIA ODK site (see link below). If you’re logging in for the first time, you will need to register using your NPI.",
        "The PALFORZIA Office Dose Kit contains test doses that range from 3 mg to 300 mg. Test doses are the first dose of each Up-Dosing level and are administered in the office. Office Dose Kit refills may also be ordered to ensure adequate inventory for your practice.",
        "<strong>For more information about ordering, call <label>1-844-PALFORZ (1-844-725-3679).</label> <a data-link='phone number' href='tel:18447253679'>1-844-PALFORZ (1-844-725-3679).</a></strong>",
      ],
    },
    {
      id: "identify",
      header: {
        title: "Determine if PALFORZIA is right for your patients",
      },
      image: "/get-started-3.png",
      alt: "6-year-old Sylvie smiling",
      figure: {
        header: "Sylvie, 6 years old",
        topic:
          "Thinks it’s funny that her dad calls her “Peanut,” even though she can’t go near them",
      },
      cta: {
        showFileIcon: true,
        label: "Download Patient Profile",
        target: "_blank",
        url: "/patient-profile.pdf",
      },
      headerTopic: {
        header: "Meet Sylvie",
        topic: "She’s ready for a proactive approach to treatment.",
        disclaimer: "Hypothetical case study",
      },
      richTopics: [
        "There are a few things to consider when determining whether PALFORZIA is the appropriate treatment for your patients. Download Sylvie’s patient profile below to learn more about her case and receive tips to help you identify appropriate patients and caregivers.",
      ],
      accordionHeader: "Is PALFORZIA right for your patients?",
      accordionLabel: "Three steps to consider if your patients could benefit from PALFORZIA",
      accordion: [
        {header: "PATIENT IDENTIFICATION", bullets:[
          "PALFORZIA may be appropriate for patients 4 through 17 years old with a confirmed diagnosis of peanut allergy and who do not demonstrate contraindications or meet exclusion criteria",
          "Patients should have manageable and controlled comorbidities and ability to commit to daily dosing, regular office visits, and carry an epinephrine injector at all times",
          "A supportive and motivated caregiver environment may help identify appropriate patients to help ensure adherence to PALFORZIA protocol"
        ]},
        {header: "PATIENT EDUCATION", bullets:[
          [<a target='_blank' href={"https://www.palforzia.com/static/treatment_handbook.pdf"}>Click here</a>, " for an overview of PALFORZIA for your patients, and ", <a href="./resources">click here</a>, " for practice and educational resources."],
          "Consider discussing options to start PALFORZIA during your next touchpoint with your patient and caregiver."
        ]},
        {header: "PATIENT REMS ENROLLMENT", bullets:[
          "Certified prescribers must enroll patients in the REMS Program to administer PALFORZIA.",
          ["Consider discussing the REMS Patient Enrollment requirements with appropriate patients and caregivers during your next touchpoint. The Patient REMS Enrollment Form can be found ", <ExternalLink url="https://www.palforziarems.com/#Main/Patients">here</ExternalLink>,"."]
        ],
        copy: [<strong>You can enroll your patient through a secure portal on the <ExternalLink url='https://palforziarems.com/'>PALFORZIA REMS website.</ExternalLink></strong>]
      }
      ],
      caption: {
        title:
          "Before a patient can begin PALFORZIA treatment, the patient, prescriber, and healthcare setting must be enrolled in the PALFORZIA REMS Program.",
        action: {
          label: "Enroll at the PALFORZIA REMS website.",
          url: "https://www.palforziarems.com/#Main",
          target: "_blank",
        },
      },
    },
    {
      id: "prescribe",
      header: {
        title: "Prescribe PALFORZIA",
      },
      image: "/get-started-4.png",
      alt: "Girl sits on playground slide",
      cta: {
        showFileIcon: true,
        label: "Download Form",
        target: "_blank",
        url: "https://PALFORZIAPro.com/static/enrollment.pdf",
      },
      caption: {
        title:
          "An Aimmune Allergy Account Manager can provide in-service education and information to help safely implement PALFORZIA into your practice.",
        action: {
          label: "get in touch",
          url: "signup#getintouch",
          internal: true
        },
      },
      richTopics: [
        ["After your patient is enrolled in the REMS Program, you can prescribe PALFORZIA by downloading, filling out, and submitting the ", <a target="_blank" href='/enrollment.pdf'>Prescription and Enrollment form.</a>],
        "Walgreens, the exclusive specialty pharmacy for PALFORZIA, will work with you and your patients to dispense the medication. This pharmacy has been certified with the PALFORZIA REMS Program.",
      ],
      accordion: [
        {header: "Insurance Eligibility and Coverage",
          copy:[
            "Before you prescribe PALFORZIA, your office Case Manager will work with the caregiver and patient to determine insurance coverage for treatment, including conducting a benefits investigation and determining whether a prior authorization or medical exception is needed.",
            "The PALFORZIA Pathway Support Program is available to provide patients and caregivers with resources and financial assistance options, including the PALFORZIA Pathway Co-Pay Savings Program. It can also help physicians identify the appropriate Specialty Pharmacy (based on payer mandate or patient/HCP preference) that can deliver PALFORZIA to the patient’s home or doctor’s office.",
            "To enroll your patient in the PALFORZIA Pathway Support Program, your office must fax a completed Prescription and Enrollment Form to the PALFORZIA Pathway Support Program at 1-844-708-0011."
          ],
          label:"Make sure your completed Form includes:",
          list:[
            "All required fields",
            "Prescriber’s signature",
            "Signature of the patient’s parent or legal guardian in the PALFORZIA Pathway Patient Authorization Section. To allow a parent/legal guardian to provide their authorization electronically, ensure you provide a current parent/legal guardian email address when you submit the Form."
          ],
          figure:"<strong>Note:</strong> Patients must be enrolled in the PALFORZIA REMS Program before a patient can be enrolled in the PALFORZIA Pathway Support Program."
        },
        {header: "Prior Authorization and Medical Exception Process",
          copy:[
            "Securing coverage for PALFORZIA may require going through a prior authorization or medical exception process with the patient’s insurance carrier. Your office reimbursement staff will be an important part of the process, including filling out and submitting the requested forms and clinical documentation required by the patient’s health plan. The PALFORZIA Pathway Support Program may help educate you/your office about the prior authorization or medical exception process for your patient’s health plan.",
            "<strong>More information on how the PALFORZIA Pathway Support Program may be able to provide education about the prior authorization process can be found</strong> <a href='/access'>here.</a>"
          ],
        }
      ],
      topicList: [
        "<strong>Walgreens Specialty Pharmacies (AllianceRX Specialty Pharmacy and Walgreens Community-Based Specialty Pharmacies):</strong> <label>1-800-445-3674</label><a data-link='phone number' href='tel:18004453674'>1-800-445-3674</a>"
      ],
    },
  ]

  return {
    header: { ...HEADER },
    tabs: [...TABS],
    cards: [...CARDS],
    references: REFERENCES,
    referencesHeader: REFERENCES_HEADER,
  }
}
