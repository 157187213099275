import React from "react"
import {
  UPDATE_NEED_CONSENT,
  UPDATE_SHOW_MODAL,
  UPDATE_LIGHTBOX_VIDEO
} from "constants/StateActionTypes"

import { useStateValue } from "state/state"
import Play from 'assets/svg/play';

const VideoLink = ({video, children}) => {
    const [, dispatch] = useStateValue()
    return (
    <a
    tabIndex={0}
    data-link="video"
    aria-label={"video link"}
      onClick={(e) => {
        e.preventDefault()
        dispatch({
          type: UPDATE_LIGHTBOX_VIDEO,
          video,
        })
      }}
    >
      {children}
      <Play/>
    </a>
    )
  }
  
  export default VideoLink

