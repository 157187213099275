import React from "react"

const Play = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
  <circle data-name="Ellipse 1" cx="12.5" cy="12.5" r="12.5" fill="#36b0a1"/>
  <path data-name="Polygon 1" d="M4.5,0,9,8H0Z" transform="translate(18 8) rotate(90)" fill="#fff"/>
</svg>

)

export default Play
