import React, {useState, useEffect} from "react"
import ReactDOM from 'react-dom'
import { Container, Figure, Row, Collapse, Card,Button } from "react-bootstrap"

import classNames from "classnames"

import Image from "../Image"
import VideoLink from '../Modal/Video';
import ExternalLink from "../Modal/ExternalLink"
import Caption from "components/Caption/Caption"
import CTAArrow from "assets/svg/cta-arrow"

import ExternalLinkIcon from "assets/svg/external-link"
import DownloadIcon from "assets/svg/download-icon"

import styles from "./GetStartedCard.module.scss"

import {
  UPDATE_LIGHTBOX_VIDEO
} from "constants/StateActionTypes"

import { useStateValue } from "state/state"


import CloseSign from "assets/svg/close"

function Collapsible({name,bullets, index, section, copy, label, figure}) {
  const [open, setOpen] = useState(false);
  if(section === 'identify'){
    return (
      <>
        <button
          tabIndex={0}
          onClick={() => setOpen(!open)}
          aria-controls={`identify-collapse-text-${index}`}
          aria-expanded={open}
        >
          <span>{index}</span>
          {name}
        <span aria-label={open ? 'close indicator' : 'open indicator'}>{open ? '–' : '+'}</span>
        </button>
        <Collapse in={open}>
          <div id={`identify-collapse-text-${index}`}>
            <ul>
              {bullets.map((item) => {
                  if(typeof item === 'object'){
                    return (
                      <li>
                      <span>
                        {item.map((copy) => (
                          copy
                        ))}
                      </span>
                    </li>
                    )
                  } else {
                    return (<li><span dangerouslySetInnerHTML={{ __html: item }}></span></li>)
                  }
              })
            }
            </ul>
            {
              copy && typeof copy === 'object' ? (
                <p>
                  {
                    copy.map((item) => (
                      item
                    ))
                  }
                </p>
              ) : (<p
              dangerouslySetInnerHTML={{ __html: copy }}
              ></p>)
            }
          </div>
        </Collapse>
      </>
    );
  } else {
    return (
      <>
        <button
          tabIndex={0}
          onClick={() => setOpen(!open)}
          aria-controls={`example-collapse-text-` + index}
          aria-expanded={open}
        >
          {name}
        <span aria-label={open ? 'close indicator' : 'open indicator'}>{open ? '–' : '+'}</span>
        </button>
        <Collapse in={open}>
          <div id={`example-collapse-text-` + index}>
          {
              copy ? copy.map(
                (item) => {
                  if(typeof item === 'object'){
                    return (
                      <p>
                      {"test"}
                  </p>
                    )
                  } else {
                    return (
                      <p
                      dangerouslySetInnerHTML={{ __html: item }}
                      ></p>
                    )
                  }
                }
                
                ) : null
          }
            {
              label ? 
            <label>{label}</label> : null
            }
            <ul>
              {bullets ? bullets.map((item) => {
                console.log(item);
                if(typeof item === 'object'){
                  return (<li>
                    <span>
                      {
                        item.map((copy) => (
                          copy
                        ))
                      }
                    </span>
                    </li>)
                } else {
                  return (<li><span dangerouslySetInnerHTML={{ __html: item }}></span></li>)
                }
              }
              ) : null}
              </ul>
            {
              figure ? 
            <Figure> 
            <Figure.Caption>
              <h4 dangerouslySetInnerHTML={{ __html: figure }}/>
            </Figure.Caption>
              </Figure> : null
            }
          </div>
        </Collapse>
      </>
    )
  }

}

const GetStartedCard = ({
  id,
  header,
  image,
  alt,
  cta,
  index,
  richTopics,
  headerTopic,
  figure,
  topicList,
  caption,
  videos,
  REMS,
  accordion,
  accordionHeader,
  accordionLabel,
  disclaimer,
  video
}) => {
  let width = typeof window === "object" ? window.innerWidth : null;
  const [, dispatch] = useStateValue()

  const handleVideo = (video, videoTitle) => {
    dispatch({
      type: UPDATE_LIGHTBOX_VIDEO,
      video,
      videoTitle
    })  
  }

  return (
    <Container
      fluid
      as={"section"}
      id={id}
      className={classNames([styles.container], {
        [styles.firstContainer]: index === 0,
        [styles.even]: index % 2 === 0,
        [styles.odd]: index % 2 === 1,
      })}
    >
      <Container>
        {width < 992 &&
          <h2 className={styles.header}>
                <span className={styles.counter}>{index + 1}</span>
                {header.title}
          </h2>
        }
        {
          index === 0 && (
            <Container
              className={styles.prepare}
            >
              {width >= 992 &&
                <h2 className={styles.header}>
                      <span className={styles.counter}>{index + 1}</span>
                      {header.title}
                </h2>
                }
                {richTopics.map((topic) => (
                <p
                  className={styles.topic}
                  key={topic}
                  dangerouslySetInnerHTML={{ __html: topic }}
                />
              ))}
              <Row>
                {
                 videos && videos.map((video) => (
                    <button
                      onClick={() => {
                        handleVideo(video.id, video.description)
                      }}
                      aria-label={`Watch ${video.description} video`}
                      className={styles.videotile}
                    >
                      <img alt={video.alt} src={video.thumbnail}/>
                      <label>{video.description}</label>
                    </button>
                ))
                }
              </Row>
              <p
                className={styles.disclaimerVideo}
              >{disclaimer}</p>
            </Container>
          )
        }
        {
          index !== 0 && (
            <Container className={styles.card}>
            <div className={styles.body}>
              {width >= 992 &&
                <h2 className={styles.header}>
                      <span className={styles.counter}>{index + 1}</span>
                      {header.title}
                </h2>
              }
              {width <= 991 && headerTopic && (
                <>
                  <h3 className={styles.headerTopic}>{headerTopic.header}</h3>
                  <h3 className={styles.headerTopic}>{headerTopic.topic}</h3>
                </>
              )}
              {figure && (
                <div className={classNames([styles.figure])}>
                  <Image
                    className={styles.figureImage}
                    node={{ publicURL: image }}
                  />
                  <div className={styles.figureCaption}>
                    <h4>{figure.header}</h4>
                    <p>{figure.topic}</p>
                  </div>
                </div>
              )}
              {width > 991 && headerTopic && (
                <>
                  <h3 className={styles.headerTopic}>{headerTopic.header}</h3>
                  <h3 className={styles.headerTopic}>{headerTopic.topic}</h3>
                </>
              )}
              {richTopics.map((topic) => {
                if(typeof topic === 'object'){
                  return (
                    <p
                    className={styles.topic}
                    key={topic}
                  >   
                    {topic.map((item) => (
                      item
                    ))}     
                  </p>         
                  )
                } else {
                  return (
                    <p
                      className={styles.topic}
                      key={topic}
                      dangerouslySetInnerHTML={{ __html: topic }}
                    />
                )
                }
              })}
              {topicList && (
                <ul className={styles.topicsList}>
                  {topicList.map((topic) => (
                    <li key={topic} 
                    dangerouslySetInnerHTML={{ __html: topic }}
                    className={styles.topicList}>
                    </li>
                  ))}
                </ul>
              )}
              {
                video && (
                  <VideoLink
                    video={video.video}
                  >
                    {video.label}
                    </VideoLink>
                )
              }
              {headerTopic && (
                <p className={styles.disclaimer}>{headerTopic.disclaimer}</p>
              )}
              {
                index !== 4 && (
                   cta.showFileIcon ? 
                    <a
                    className={classNames([styles.cta], {
                      [styles.large]: cta.label.length > 23,
                    })}
                    target={"_blank"}
                    href={cta.url}
                  >
                    {cta.label}
                    {cta.showFileIcon ? (
                      <DownloadIcon classes={styles.icon} />
                    ) : (
                      <ExternalLinkIcon classes={styles.icon} />
                    )}
                  </a>
                    :
                    index !== 1 ? <ExternalLink
                      className={styles.cta}
                      url={cta.url}
                  >
                    {cta.label}
                      <ExternalLinkIcon classes={styles.icon} />
                  </ExternalLink> : null
                )
              }
            </div>
            <div
              className={classNames([styles.imageContainer], {
                [styles.hideMobile]: figure || index === 3,
              })}
            >
              <Image className={styles.image} node={{ publicURL: image }} alt={alt}/>
              {figure && (
                <div className={styles.figureCaption}>
                  <h4>{figure.header}</h4>
                  <p>{figure.topic}</p>
                </div>
              )}
            </div>
            {
              index === 1 && REMS && (
                <Row>
                    <h3>{REMS.header}</h3>
                    {
                      REMS.copy.map((yo) => {
                        if(typeof yo === 'object'){
                          return (
                            <p>
                            {yo.map((copy) => 
                              copy
                            )}
                          </p>
                          )
                        } else {
                          return (
                            <p dangerouslySetInnerHTML={{ __html: yo }}/>
                          )
                        }
                        }
                      )
                    }
                    <ExternalLink
                        className={styles.cta}
                        url={cta.url}
                    >
                      {cta.label}
                        <ExternalLinkIcon classes={styles.icon} />
                    </ExternalLink>
                </Row>
              )
            }
          </Container>
          )
        }
{
  index === 3 && accordion && (
    <>
    <Container>
      <label>{accordionHeader}</label>
      <Row
        className={styles.patientAccordion}
      >
        <h4>{accordionLabel}</h4>
        {
          accordion.map((item,index) => (
              <Collapsible
                index={index}
                section={id}
                name={item.header}
                index={index + 1}
                bullets={item.bullets}
                copy={item.copy ? item.copy : null}
              />
          )
        )}
      </Row>
    </Container>
    <Figure
      className={styles.notice}
    >
      <div className={styles.exclamation}><span>!</span></div>
              <h3>
              {`${caption.title} `}
              <ExternalLink
                url={caption.action.url}
                className={styles.captionLink}
              >
                {caption.action.label}
              </ExternalLink>
              </h3>
          </Figure>
    </>
  )
}
        {index !== 3 && index !== 4 && caption && (
            <Figure>
              <h3>
              {`${caption.title} `}
              </h3>
              {
                index === 4 ? 
                <a href={caption.action.url}>
                {caption.action.label}
                <CTAArrow classes={styles.icon} />
                </a> 
                :
                <ExternalLink
                url={caption.action.url}
                className={styles.captionLink}
              >
                {caption.action.label}
              </ExternalLink> 
              }
          </Figure>
        )}
        {
  index === 4 && accordion && (
    <Container>
      <Row
        className={styles.prescribeAccordion}
      >
        {
          accordion.map((item,index) => (
              <Collapsible
                index={index}
                name={item.header}
                bullets={item.list ? item.list : null}
                label={item.label ? item.label : null}
                copy={item.copy ? item.copy : null}
                figure={item.figure ? item.figure : null}
              />
          )
        )}
      </Row>
    </Container>
  )
}
      </Container>
    </Container>
  )
}

export default GetStartedCard
