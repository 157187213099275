import React, {useEffect} from "react"

import { graphql } from "gatsby"

import { StateProvider } from "state/state"
import { reducer, initialState } from "state/reducer"

import { useGetInTouchMenuData } from "hooks/contentful/useGetInTouchMenuData"
import { useGetStartedData } from "hooks/contentful/pages/useGetStartedData"

import Layout from "components/Layout"
import SEO from "components/Seo"
import PageTitle from "components/PageTitle/PageTitle"
import TabbedNav from "components/TabbedNav/TabbedNav"
import CaptionCTA from "components/CaptionCTA/CaptionCTA"
import GetStartedCard from "components/GetStartedCard/GetStartedCard"

import "../styles/main.scss"
import styles from "../styles/pages/getstarted.module.scss"

const GetStarted = ({ data, location }) => {
  const captionCTA = useGetInTouchMenuData()
  const {
    header,
    tabs,
    references,
    referencesHeader,
    cards,
  } = useGetStartedData()
  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <Layout referencesHeader={referencesHeader} references={references}>
        <SEO title={data.page.title} description={data.page.description} />
        <main className={styles.getstarted}>
          <PageTitle title={header.title}></PageTitle>
          <TabbedNav hash={location.hash} tabs={tabs} className={styles.tabbedNav} />
          <section className={styles.container}>
            {cards.map((card, index) => {
              return <GetStartedCard key={index} {...card} index={index} />
            })}
          </section>
        </main>
      </Layout>
    </StateProvider>
  )
}

export default GetStarted

export const query = graphql`
  query {
    page: contentfulHcpPage(label: { eq: "Get Started" }) {
      label
      url
      title
      description
    }
  }
`
